import React from "react";

//Material
import {
  Grid,
  makeStyles, useMediaQuery,
  useTheme
} from "@material-ui/core";

//Utility
import TypographyHelper from "./TypographyHelper";

const useStyles = makeStyles({
  container: {
    padding: "1em",
    margin: "0 auto",
    minHeight: "30em",
    maxWidth: "80em",
  },
  containerText: {
    padding: "1em"
  },
  containerImage: {
    borderRadius: "0.25em",
  },
});

export default function Card(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mediaSM = useMediaQuery(theme.breakpoints.down("sm"));
  const mediaXS = useMediaQuery(theme.breakpoints.down("xs"));
  
  return (
    <React.Fragment>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={classes.container}
        style={{padding: mediaSM && 0}}
      >
        {/*PICTURE LEFT*/}
        {props.pictureSide === "left" && !mediaSM &&
        <Grid
          item
          xs={12}
          md={6}
          className={classes.containerImage}
        >
          <div
            style={{
              minHeight: "30em",
              backgroundImage: `url(${props.url})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        }
        
        {/*TEXT SECTION*/}
        <Grid item xs={12} md={6} className={classes.containerText}>
          <Grid
            container
            justify={"center"}
            alignContent={"space-between"}
            style={{paddingBottom: mediaSM && "3em",  maxWidth: mediaSM && "40em", margin: "0 auto"}}
          >
            {/*HEADER*/}
            <Grid item xs={12} style={{padding: "1em 0"}}>
              <TypographyHelper
                variant={"h4"}
                color={"black"}
                fontWeight={"bold"}
                align={"center"}
              >
                {props.headerText ? props.headerText : ""}
              </TypographyHelper>
            </Grid>
            
            {/*MOBILE PICTURE*/}
            <Grid item xs={12}>
              {mediaSM &&
              <Grid
                item
                xs={12}
                className={classes.containerImage}
              >
                <div
                  style={{
                    minHeight: mediaXS ? "18em" : "25em",
                    backgroundImage: `url(${props.url})`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </Grid>
              }
            </Grid>
            
            {/*CONTENT*/}
            <Grid item xs={12} className={classes.body} style={{paddingTop: mediaSM ? "1em" : 0}}>
              <TypographyHelper
                variant={"body"}
                color={"black"}
                align={mediaSM ? "center" : "justify"}
              >
                {props.bodyText ? props.bodyText : ""}
              </TypographyHelper>
            </Grid>
          </Grid>
        </Grid>
        
        {/*PICTURE RIGHT*/}
        {props.pictureSide === "right" && !mediaSM &&
        <Grid
          item
          xs={12}
          md={6}
          className={classes.containerImage}
        >
          <div
            style={{
              minHeight: "30em",
              backgroundImage: `url(${props.url})`,
              backgroundPosition: "center",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        }
      </Grid>
    </React.Fragment>
  );
}
