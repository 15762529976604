import React from "react";

//Material-UI
import {
  Grid,
  makeStyles
} from "@material-ui/core";

//Components
import InfoCard from "../shared/InfoCard";
import TypographyHelper from "../shared/theme/TypographyHelper";

//Images
import qos from "../shared/assets/images/qualtiyofservice.jpg";
import ba from "../shared/assets/images/beingapproachable.png";
import rpp from "../shared/assets/images/realisticpricepoints.jpg";
import ee from "../shared/assets/images/exceedingexpectations.jpg";
import md from "../shared/assets/images/modulardesign.png";
import cs from "../shared/assets/images/customsolutions.jpg";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh"
  },
  container: {
    padding: "2em"
  },
  containerSection: {
    padding: "5em 0"
  },
  background: {
    minHeight: "40em",
    backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7) ), url(https://images5.alphacoders.com/432/thumb-1920-432724.jpg)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed"
  },
  containerCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2em"
  },
  containerHeader: {
    padding: "2em 0 0 0"
  },
  containerText: {
    padding: "2em 1em 0 1em"
  }
});

let imgqos = qos
let imgba = ba
let imgrpp = rpp
let imgee = ee
let imgmd = md
let imgcs = cs

const infoCardContent = [
  {
    title: "Quality of Service",
    imageUrl: imgqos,
    content:
      "Our ethos of being approachable and offering realistic price points " +
      "combined with our modular design ensures that we are always able to " +
      "exceed your expectations offering you unmatched quality of service!"
  },
  {
    title: "Being approachable",
    imageUrl: imgba,
    content:
      "We do not have a call centre and don’t want one! We " +
      "allocate a service agent to you and that is the number " +
      "you call for any issues, the Agent then takes full responsibility " +
      "of your service until the result is achieved and communicated. "
  },
  {
    title: "Realistic Price Points",
    imageUrl: imgrpp,
    content:
      "Our service comes in at a slightly higher price point. " +
      "This is on purpose because we never want to “water  down” " +
      "our service in leu of profit, we cater to people that have " +
      "experienced “el-cheapo internet” and are now ready for a " +
      "quality Broadband solution that can actually be used. "
  },
  {
    title: "Exceeding Expectations",
    imageUrl: imgee,
    content:
      "Although we have lost count of how many times our Customers " +
      "have quoted our service as being “Brilliant” we have never " +
      "lost the need to always have that feedback. For this reason, " +
      "we have calibrated our service to ensure that it will always be " +
      "better than what our customers have experienced before from other ISP’s."
  },
  {
    title: "Modular Design",
    imageUrl: imgmd,
    content:
      "We have set our maximum contention ratio and will always " +
      "ensure we do no not exceed this. By ensuring we follow our " +
      "formula we always deliver a constant, stable solution never leaving " +
      "you with a diluted service."
  },
  {
    title: "Custom Solutions",
    imageUrl: imgcs,
    content:
      "As no two customers are the same there will be no two identical " +
      "solutions. Keeping this in mind we consult you and find out your need " +
      "and deliver exactly what you require."
  }
];

export default function About() {
  const classes = useStyles();
  
  return (
    <React.Fragment>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={classes.root}
      >
        <Grid item xs={12}>
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
            className={classes.container}
          >
            {/*HEADER*/}
            <Grid item xs={12} md={8} lg={7} className={classes.containerHeader}>
              <TypographyHelper
                variant={"h3"}
                color={"black"}
                align={"center"}
                fontWeight={"bold"}
              >
                Introducing a new fibre internet experience
              </TypographyHelper>
            </Grid>
            
            {/*TEXT*/}
            <Grid item xs={12} md={8} lg={7} className={classes.containerText}>
              <TypographyHelper
                variant={"body"}
                color={"black"}
                align={"center"}
              >
                BCFibre is proudly brought to you by BrilliantCom.
                The goal of BCFibre is to supply an unmatched internet service.
                By Supplying a Service based on our principles of high speeds
                and low contention, we ensure you have a true Fibre experience.
              </TypographyHelper>
            </Grid>
            
            {/*HEADER*/}
            <Grid item xs={12} md={8} lg={7} className={classes.containerHeader}>
              <TypographyHelper
                variant={"h3"}
                color={"black"}
                align={"center"}
                fontWeight={"bold"}
              >
                What makes us different?
              </TypographyHelper>
            </Grid>
            
            {/*CARDS*/}
            <Grid item xs={12} xl={8}>
              <Grid
                container
                justify={"center"}
                alignItems={"center"}
              >
                {infoCardContent.map((card, index) =>
                  <Grid key={index} item xs={12} md={6} lg={4} className={classes.containerCard}>
                    <InfoCard
                      title={card.title}
                      imageUrl={card.imageUrl}
                      content={card.content}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          
          {/*NEW SECTION*/}
          <Grid item xs={12} className={classes.background}>
            <Grid
              container
              justify={"center"}
              alignItems={"center"}
              className={classes.containerSection}
            >
              {/*HEADER*/}
              <Grid item xs={12} md={8} lg={7} className={classes.containerHeader}>
                <TypographyHelper
                  variant={"h3"}
                  color={"white"}
                  align={"center"}
                  fontWeight={"bold"}
                  textShadow={"0 1px 0 black"}
                >
                  Our vision
                </TypographyHelper>
              </Grid>
              
              {/*TEXT*/}
              <Grid item xs={12} md={8} lg={7} className={classes.containerText}>
                <TypographyHelper
                  variant={"body"}
                  color={"white"}
                  align={"center"}
                  textShadow={"0 1px 0 black"}
                >
                  We envision our network growing by always retaining a personal
                  relationship with our customers. We will constantly upgrade our
                  infrastructure, ensuring a great Internet experience with Uncapped,
                  Unshaped and Low Contented service, while always looking towards the future.
                </TypographyHelper>
              </Grid>
              
              {/*HEADER*/}
              <Grid item xs={12} md={8} lg={7} className={classes.containerHeader}>
                <TypographyHelper
                  variant={"h3"}
                  color={"white"}
                  align={"center"}
                  fontWeight={"bold"}
                  textShadow={"0 1px 0 black"}
                >
                  Our mission
                </TypographyHelper>
              </Grid>
              
              {/*TEXT*/}
              <Grid item xs={12} md={8} lg={7} className={classes.containerText}>
                <TypographyHelper
                  variant={"body"}
                  color={"white"}
                  align={"center"}
                  textShadow={"0 1px 0 black"}
                >
                  It is our Mission to eradicate frustrations experienced when using
                  internet. BCFibre is purposefully establishing a dependable and low
                  contended Internet Solution, ensuring a great internet experience,
                  which is a challenge worth striving towards.
                </TypographyHelper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}