import React from "react";

//Material UI
import {
  Grid,
  makeStyles,
  Divider
} from "@material-ui/core";

//Shared
import TypographyHelper from "../shared/theme/TypographyHelper";
import Card from "../shared/theme/Card";

//Image

const useStyles = makeStyles({
  container: {
    padding: "2em",
    minHeight: "100vh",
  },
  containerSection: {
    padding: "5em 0"
  },
  background: {
    minHeight: "40em",
    backgroundImage: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7) ), url(https://skhuta.db.files.1drv.com/y4m-BB1laBFWWMFvCm0qTnlQjBSVfTLZ12d51Zby-0n4wQjZsWAE-vcQYnXU4h524nuKRImN6_WbO-wNBz_HKcy--RCMl-Ec0qa0AeEyvAxn0q57J2zVlwZCerEJ1zenktNQa3U76U2hLwTrnAZfi6WaUvrpF3ft0Ox9KYKKw0Ht8z0pHQ_zoLAroTMlTzNtogPtU3sPXFAtPM0J2jymgIa-w?width=2121&height=1414&cropmode=none)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed"
  },
  containerCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3em"
  },
  containerHeader: {
    padding: "2em 0 2em 0"
  },
  containerText: {
    padding: "2em 1em 0 1em"
  }
});

export default function Solutions() {
  const classes = useStyles();
  
  const cardContent = [
    {
      header: "FTTB",
      body: "Fibre To The Business is a business grade connection with Traffic Prioritisation. This is the pinnacle of connectivity with low to no contention. This solution is ideal for all your internet and data requirements, be it High User counts, Cloud services, Online Accounting, CRM, XRM systems and accessing Hosted services. Our Uncapped and Symmetrical connections are perfect for these services and more.",
      url: require("../shared/assets/images/fttb.png")
    },
    {
      header: "FTTH",
      body: "Fibre To The Home is a much cheaper service than FTTB. Generally, ISP’s will contend this service at a much higher level and include data limitations such as soft-caps and shaping protocols to reduce their costs and ensuring maximum profit. This is not our approach, we deliver a product that is superior in both experience and service.",
      url: require("../shared/assets/images/FTTH.png")
    },
    {
      header: "APARTMENTS",
      body: "Our Apartments solution is where we cable and reticulate the entire building. This allows us to bring a dedicated Business Grade Fibre Link and distribute it locally. The advantage of this is that we can then dictate and manage the link and contention, offering a service superior to FTTH but at close to FTTH costings. This is a custom solution and we can consult with the Management Board to deliver a tailor-made solution.",
      url: require("../shared/assets/images/Front-Apartment.png")
    },
    {
      header: "RESIDENTIAL ESTATES",
      body: "Essentially the same principle as an Apartment building, we can Implement a Local Network that allows us to build custom packages for the Estate. We can integrate all existing services that are present and consolidate the whole solution. Combine DSTV, CCTV, Access Control and create a local Network to connect the community.",
      url: require("../shared/assets/images/Estate.png")
    },
    {
      header: "BUSINESS PARKS",
      body: "FTTB is generally a costly service due higher cost of a low contended link. Here we would increase the size of the initial line to the park and then creating a local Network, we activate the economy of scales and so doing bring individual FTTB services at a much-reduced costing point. These services will still have low to no contention and is perfect for the budget conscious consumer.",
      url: require("../shared/assets/images/BPnbg.png")
    }
  ];
  
  return (
    <React.Fragment>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={classes.container}
      >
        {/*TEXT*/}
        <Grid item xs={12} md={8} lg={7} className={classes.containerHeader} style={{paddingBottom: "3em"}}>
          <TypographyHelper
            variant={"body"}
            color={"black"}
            align={"center"}
          >
            BCFibre as BrilliantCom we have always been a Solutions Builder.
            We specialise in Solving the problem at hand or delivering a solution
            to your requirements. Either way the problem is solved. We have earmarked
            our versions of FTTH, FTTB and Special Projects to meet your needs regarding
            broadband internet.
          </TypographyHelper>
        </Grid>
        
        {/*CARDS*/}
        <Grid item xs={12}>
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
          >
            {cardContent.map((card, index) =>
              <Grid item xs={12} key={index}>
                <Card
                  headerText={card.header}
                  bodyText={card.body}
                  url={card.url}
                  pictureSide={index % 2 ? "right" : "left"}
                />
                
                {index !== cardContent.length - 1 &&
                <Divider/>
                }
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
  
      <Grid
        container
        alignContent={"center"}
        justify={"center"}
        className={classes.background}
      >
        {/*HEADER*/}
        <Grid item xs={12} md={8} lg={7} className={classes.containerHeader}>
          <TypographyHelper
            variant={"h3"}
            color={"white"}
            align={"center"}
            fontWeight={"bold"}
            textShadow={"0 1px 0 black"}
          >
            Our referral program
          </TypographyHelper>
        </Grid>
    
        {/*TEXT*/}
        <Grid item xs={12} md={8} lg={7} className={classes.containerText}>
          <TypographyHelper
            variant={"body"}
            color={"white"}
            align={"center"}
            textShadow={"0 1px 0 black"}
          >
            Refer your Friends, Family and Business partners to us and reap
            the benefit! It is our belief that value should be added where
            possible and no good deed goes un-rewarded! For every one of your
            referrals we connect onto our network, we will reduce the cost of
            your connection by a set amount. Who knows, one day we might even pay
            you to use our service?!!
          </TypographyHelper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}