import React, {useEffect, useState} from "react";

//Material UI
import {
  makeStyles
} from "@material-ui/core";

//Pages
import Home from "./Home";
import About from "./About";
import Solutions from "./Solutions";
import Contact from "./Contact";

//Shared
import SectionBanner from "../shared/SectionBanner";

const useStyles = makeStyles({
  anchor: {
    display: "block",
    marginTop: "-5em",
    height: "5em",
    backgroundColor: "transparent"
  }
});

const pages = [
  {
    anchor: <div id={"Home"}/>,
    content: <Home/>
  },
  {
    anchor: <div id={"About"}/>,
    banner: <SectionBanner text={"About"}/>,
    content: <About/>
  },
  {
    anchor: <div id={"Solutions"}/>,
    banner: <SectionBanner text={"Solutions"}/>,
    content: <Solutions/>
  },
  {
    anchor: <div id={"Contact"}/>,
    banner: <SectionBanner text={"Contact"}/>,
    content: <Contact/>
  }
];

export default function PageRenderer(props) {
  const classes = useStyles();
  
  const [authToken, setAuthToken] = useState();
  
  useEffect(() => {
    setAuthToken(props.authToken);
  }, [props.authToken]);
  
  return (
    <React.Fragment>
      {/*HOME*/}
      <div id={"Home"} className={classes.anchor}/>
      <Home/>
    
      {/*ABOUT*/}
      <div id={"About"} className={classes.anchor}/>
      <SectionBanner text={"About"}className={classes.anchor}/>
      <About/>
      
      {/*SOLUTIONS*/}
      <div id={"Solutions"} className={classes.anchor}/>
      <SectionBanner text={"Solutions"}/>
      <Solutions/>
      
      {/*CONTACT*/}
      <div id={"Contact"} className={classes.anchor}/>
      <SectionBanner text={"Contact"}/>
      <Contact authToken={authToken}/>
    </React.Fragment>
  );
}