/**
 * User: Johan Grobler
 * Date: 2020/07/22
 * Time: 16:39
 * Project: pch-flo-webui
 * Description : Add page description
 */

// React
import React, {useState, useEffect} from "react";

// Material
import {
  makeStyles
} from "@material-ui/core";

//Shared
import * as colors from "./ColorRef";

//Utility
import {isEmpty} from "lodash";

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: "3.5em",
    letterSpacing: 1
  },
  h2: {
    fontSize: "2.5em",
    letterSpacing: 1.7
  },
  h3: {
    fontSize: "2em",
    letterSpacing: 1.7
  },
  h4: {
    fontSize: "1.5em",
    letterSpacing: 1.7
  },
  body: {
    fontSize: "1.25em",
    letterSpacing: 1
  },
  button: {
    fontSize: "0.9em",
    letterSpacing: 1
  },
  label: {
    fontSize: "1em",
    letterSpacing: 1
  }
}));

export default function TypographyHelper(props) {
  //Classes
  const classes = useStyles();
  
  //States
  const [text, setText] = useState("");
  const [params, setParams] = useState({});
  
  const populateParams = () => {
    const tempParams = {
      variant: props.variant && !isEmpty(props.variant) ? props.variant : "body",
      contrast: props.contrast && !isEmpty(props.contrast) ? props.contrast : "base",
      color: props.color && !isEmpty(props.color) ? props.color : "black",
      fontWeight: props.fontWeight && !isEmpty(props.fontWeight) ? props.fontWeight : "default",
      fontFamily: props.fontFamily && !isEmpty(props.fontFamily) ? props.fontFamily : "Open Sans",
      textDecoration: props.textDecoration && !isEmpty(props.textDecoration) ? props.textDecoration : "none",
      display: props.display && !isEmpty(props.display) ? props.display : "block",
      align: props.align && !isEmpty(props.align) ? props.align : "left",
      nowrap: props.nowrap && !isEmpty(props.nowrap) ? props.nowrap : false,
      characterCap: props.characterCap && !isEmpty(props.characterCap) ? props.characterCap : -1,
      textShadow: props.textShadow ? props.textShadow : "",
      opacity: props.opacity ? props.opacity : "1",
      cursor: props.cursor ? props.cursor : "default",
    };
    
    setParams(tempParams);
  };
  
  const populateText = () => {
    const tempText = props.children && !isEmpty(props.children) ? props.children : "";
    
    setText(tempText);
  };
  
  //Effects
  useEffect(() => {
    populateText();
    populateParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  
  return (
    <React.Fragment>
      {text && !isEmpty(text) ? (
        <React.Fragment>
          <div
            className={classes[params.variant]}
            onClick={() => (props.onClick ? props.onClick() : null)}
            style={{
              display: params.display,
              color: params.contrast === "dark" ? colors.dark[params.color] : params.contrast === "light" ? colors.light[params.color] : colors.base[params.color],
              textDecoration: params.textDecoration,
              textAlign: params.align,
              textShadow: params.textShadow,
              opacity: params.opacity,
              cursor: params.cursor,
              fontWeight: params.fontWeight,
            }}
          >
            {text}
          </div>
          
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}