import React, {useState} from "react";

//Pages
import PageRenderer from "./components/pages/PageRenderer";
import AuthService from "./services/AuthService";

//Styles
import "./App.scss";

//Components
import Navigation from "./components/navigation/Navigation";

//Utils
import {loadReCaptcha} from "react-recaptcha-google";

export default function App() {
  loadReCaptcha();
  
  const [authToken, setAuthToken] = useState();
  
  return (
    <React.Fragment>
      {/*NAVIGATION*/}
      <Navigation/>
      
      {/*PAGES*/}
      <PageRenderer authToken={authToken && authToken}/>
      
      {/*ReCAPTCHA*/}
      <AuthService authToken={(token) => setAuthToken(token)}/>
    </React.Fragment>
  );
}
