import React from "react";

//Material-UI
import {
  makeStyles
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

//Shared
import TypographyHelper from "./theme/TypographyHelper";

const useStyles = makeStyles({
  containerMain: {
    padding: "5em 0",
    boxShadow: "2px 2px 6px #737373",
  }
});

export default function SectionBanner(props) {
  const classes = useStyles();
  
  return (
    <React.Fragment>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={classes.containerMain}
      >
        {/*VALUE*/}
        <Grid item xs={12}>
          <TypographyHelper
            align={"center"}
            color={"blue"}
            variant={"h2"}
            contrast={"dark"}
            fontWeight={"bold"}
          >
            {props.text ? props.text : ""}
          </TypographyHelper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}