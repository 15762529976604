import React, {useContext, useEffect, useState} from "react";

//Material-UI
import {
  makeStyles,
  Grid,
  IconButton,
  TextField,
  Snackbar,
  MenuItem
} from "@material-ui/core";
import {MdClose} from "react-icons/md";

//Utility
import {isEmpty} from "lodash";

//Shared
import * as colors from "../shared/theme/ColorRef";
import TypographyHelper from "../shared/theme/TypographyHelper";

//services
import MailService from "../../services/MailService";

const useStyles = makeStyles({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    height: "3em",
    backgroundColor: colors.dark["blue"],
    borderRadius: "0.25em",
    transition: "all 0.3s linear",
    "&:hover": {
      backgroundColor: colors.base["blue"]
    }
  },
  buttonDisabled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    height: "3em",
    backgroundColor: colors.base["grey"],
    borderRadius: "0.25em"
  },
  container: {
    padding: "3em 1em"
  },
  containerSteps: {
    padding: "3em 1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  containerHeader: {
    padding: "2em 1em 0 1em"
  },
  containerText: {
    padding: "2em 1em 0 1em"
  },
  footer: {
    marginTop: "15em",
    paddingTop: "25em",
    backgroundColor: colors.dark["blue"]
  },
  item: {
    padding: "0 0.25em 0.5em 0.25em"
  },
  containerMain: {
    maxWidth: "100em",
    margin: "0 auto",
    minHeight: "calc(100vh - 260px)"
  },
  snackbarLightGreen: {
    marginTop: "5em",
    zIndex: 9999999999,
    backgroundColor: "#00d64e"
  },
  snackbarLightYellow: {
    marginTop: "5em",
    zIndex: 9999999999,
    backgroundColor: "#b1970c"
  },
  snackbarLightRed: {
    marginTop: "5em",
    zIndex: 9999999999,
    backgroundColor: "#d90909"
  }
});

const speeds = [
  {
    key: "10/10",
    value: "10/10Mbps"
  },
  {
    key: "20/20",
    value: "20/20Mbps"
  },
  {
    key: "50/50",
    value: "50/50Mbps"
  },
  {
    key: "100/100",
    value: "100/100Mbps"
  },
  {
    key: "200/200",
    value: "200/200Mbps"
  }
];

export default function Contact(props) {
  const classes = useStyles();
  
  const [formValues, setFormValues] = useState({
    name: "",
    surname: "",
    email: "",
    cell: "",
    address: "",
    speed: "",
    isReferred: "",
    message: ""
  });
  const [authToken, setAuthToken] = useState();
  const [status, setStatus] = useState(false);
  const [snackbar, setSnackbar] = useState({
    autoHideDuration: 3000,
    anchor: {
      vertical: "top",
      horizontal: "center"
    },
    message: "Oops! Something went wrong.",
    open: false,
    variant: "warning"
  });
  
  useEffect(() => {
    setAuthToken(props.authToken);
  }, [props.authToken]);
  
  useEffect(() => {
    console.log(authToken);
  }, [authToken]);
  
  const sendMail = async () => {
    
    if (status === false)
      if (formValues && !isEmpty(formValues)) {
        await MailService.send({
          message: formValues
        });
        
        setSnackbar({
          ...snackbar,
          variant: "success",
          message: "Mail sent successfully!",
          open: true
        });
        
        setStatus(true);
      } else {
        setSnackbar({
          ...snackbar,
          variant: "error",
          message: "Oops! Something went wrong.",
          open: true
        });
      }
  };
  
  const handleChange = (name, evt) => {
    if (evt && (!isEmpty(evt) || evt.length > 0)) {
      setFormValues({...formValues, [name]: evt.target.value});
    }
  };
  
  return (
    <React.Fragment>
      <Grid
        container
        justify={"center"}
        alignItems={"center"}
        className={classes.containerMain}
      >
        <Grid item xs={12}>
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
          >
            {/*HEADER*/}
            <Grid item xs={12} md={8} className={classes.containerHeader}>
              <TypographyHelper
                variant={"h3"}
                color={"black"}
                fontWeight={"bold"}
                align={"center"}
              >
                Our team will get in touch to tailor your solution.
              </TypographyHelper>
            </Grid>
            
            {/*TEXT*/}
            <Grid item xs={12} md={8} className={classes.containerText}>
              <TypographyHelper
                variant={"body"}
                color={"black"}
                align={"center"}
              >
                Please register your interest on our contact page so we can keep you updated and provide you with more information
              </TypographyHelper>
            </Grid>
            
            {/*CONTACT FORM*/}
            <Grid item xs={12} md={8} className={classes.container}>
              <Grid
                container
                justify="center"
                alignItems="center"
              >
                {/*NAME*/}
                <Grid item xs={12} md={6} className={classes.item}>
                  <TextField
                    fullWidth
                    required={true}
                    variant={"outlined"}
                    label={"Name"}
                    name={"name"}
                    value={formValues.name}
                    onChange={(evt) => handleChange("name", evt)}
                  />
                </Grid>
                
                {/*SURNAME*/}
                <Grid item xs={12} md={6} className={classes.item}>
                  <TextField
                    fullWidth
                    required={true}
                    variant={"outlined"}
                    label={"Surname"}
                    name={"surname"}
                    value={formValues.surname}
                    onChange={(evt) => handleChange("surname", evt)}
                  />
                </Grid>
                
                {/*EMAIL*/}
                <Grid item xs={12} md={6} className={classes.item}>
                  <TextField
                    fullWidth
                    required={true}
                    variant={"outlined"}
                    label={"Email"}
                    name={"email"}
                    value={formValues.email}
                    onChange={(evt) => handleChange("email", evt)}
                  />
                </Grid>
                
                {/*CELL*/}
                <Grid item xs={12} md={6} className={classes.item}>
                  <TextField
                    fullWidth
                    required={true}
                    variant={"outlined"}
                    label={"Cell"}
                    name={"cell"}
                    value={formValues.cell}
                    onChange={(evt) => handleChange("cell", evt)}
                  />
                </Grid>
                
                {/*ADDRESS*/}
                <Grid item xs={12} className={classes.item}>
                  <TextField
                    fullWidth
                    required={true}
                    variant={"outlined"}
                    label={"Address"}
                    name={"address"}
                    value={formValues.address}
                    onChange={(evt) => handleChange("address", evt)}
                  />
                </Grid>
                
                {/*SPEED*/}
                <Grid item xs={12} md={6} className={classes.item}>
                  <TextField
                    fullWidth
                    select
                    variant={"outlined"}
                    label={"Speed"}
                    name={"speed"}
                    value={formValues.speed}
                    onChange={(evt) => handleChange("speed", evt)}
                  >
                    {speeds.map((speed, index) => (
                      <MenuItem value={speed.key} key={index}>{speed.value}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                
                {/*WERE YOU REFERRED*/}
                <Grid item xs={12} md={6} className={classes.item}>
                  <TextField
                    fullWidth
                    select
                    variant={"outlined"}
                    label={"Were you referred?"}
                    name={"referred"}
                    value={formValues.isReferred}
                    onChange={(evt) => handleChange("isReferred", evt)}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </TextField>
                </Grid>
                
                {/*MESSAGE*/}
                <Grid item xs={12} className={classes.item}>
                  <TextField
                    fullWidth
                    multiline
                    rows="4"
                    variant={"outlined"}
                    label={"Message"}
                    name={"message"}
                    value={formValues.message}
                    onChange={(evt) => handleChange("message", evt)}
                  />
                </Grid>
                
                {/*BUTTON*/}
                <Grid
                  item
                  xs={12}
                  className={classes.item}
                  style={{
                    paddingTop: "1em"
                  }}
                >
                  <div
                    className={
                      formValues.name !== ""
                      && formValues.email !== ""
                      && formValues.cell !== ""
                      && formValues.address !== ""
                      && authToken && !isEmpty(authToken)
                        ?
                        classes.button
                        :
                        classes.buttonDisabled
                    }
                    onClick={() => {
                      if (
                        formValues.name !== ""
                        && formValues.email !== ""
                        && formValues.cell !== ""
                        && formValues.address !== ""
                        && authToken && !isEmpty(authToken)
                      ) {
                      sendMail();
                      }
                    }}
                  >
                    <TypographyHelper
                      variant={"label"}
                      color={"white"}
                      fontWeight={"bold"}
                    >
                      SEND
                    </TypographyHelper>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      {/*SNACKBAR*/}
      <Snackbar
        data-testid={"main-snackbar"}
        autoHideDuration={snackbar.autoHideDuration ? snackbar.autoHideDuration : 3000}
        anchorOrigin={{
          vertical: snackbar.anchor.vertical ? snackbar.anchor.vertical : "top",
          horizontal: snackbar.anchor.horizontal ? snackbar.anchor.horizontal : "center"
        }}
        ContentProps={{
          classes: {
            root:
              snackbar.variant === "error"
                ?
                classes.snackbarLightRed
                :
                snackbar.variant === "warning"
                  ?
                  classes.snackbarLightYellow
                  :
                  classes.snackbarLightGreen
          }
        }}
        open={snackbar.open}
        message={snackbar.message}
        onClose={() => setSnackbar({...snackbar, open: false})}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbar({...snackbar, open: false})}>
              <MdClose/>
            </IconButton>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
}