import React from "react";

//Material UI
import {
  Grid,
  makeStyles
} from "@material-ui/core";

//Shared
import TypographyHelper from "../shared/theme/TypographyHelper";

//Image
import background from "../shared/assets/images/background.jpg"


const useStyles = makeStyles({
  containerMain: {
    minHeight: "100vh",
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed"
  },
  item: {
    maxWidth: "75em",
    padding: "5em 2em"
  },
});

export default function Home() {
  const classes = useStyles();
  
  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={classes.containerMain}
    >
      <Grid item xs={12} className={classes.item}>
        <Grid
          container
          justify={"center"}
          alignItems={"center"}
        >
          {/*MAIN HEADER*/}
          <Grid item xs={12} style={{padding: "0 0 5em 0"}}>
            <TypographyHelper
              align={"center"}
              color={"white"}
              variant={"h1"}
              fontWeight={"bold"}
              textShadow={"0 1px 0 black"}
            >
              A different kind of company, a different kind of fibre
            </TypographyHelper>
          </Grid>
          
          {/*SUB HEADER 1*/}
          <Grid item xs={12} style={{padding: "0 0 0.5em 0"}}>
            <TypographyHelper
              align={"center"}
              color={"white"}
              variant={"h3"}
              textShadow={"0 1px 0 black"}
            >
              The eradication of broadband suffocation.
            </TypographyHelper>
          </Grid>
          
          {/*SUB HEADER 2*/}
          <Grid item xs={12} className={classes.heading} style={{padding: "0.5em 0 0 0"}}>
            <TypographyHelper
              align={"center"}
              color={"white"}
              variant={"h3"}
              textShadow={"0 1px 0 black"}
            >
              In a world of technology, people make the difference.
            </TypographyHelper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}