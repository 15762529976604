import React from 'react';
import ReactDOM from 'react-dom';

//Material-UI
import CssBaseline from "@material-ui/core/CssBaseline";

//Components
import App from './App';

//Utility
import WebFontLoader from 'webfontloader';

WebFontLoader.load({
  google: {
    families: [
      'Open Sans:400, 600:latin-ext',
      'Public Sans: 400, 500 ,600, 700, 800, 900:latin-ext'
    ]
  }
})

ReactDOM.render(
  <React.Fragment>
    <CssBaseline/>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
