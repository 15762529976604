import React, {useState} from "react";

//Material-UI
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  useTheme,
  Grid,
  useMediaQuery,
  makeStyles,
  IconButton
} from "@material-ui/core";

//Icons
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CallIcon from "@material-ui/icons/Call";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";

//Shared
import Logo from "../shared/theme/Logo";
import * as colors from "../shared/theme/ColorRef";
import TypographyHelper from "../shared/theme/TypographyHelper";

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.base["white"],
    minHeight: "5em",
    boxShadow: "2px 2px 6px #737373",
    position: "fixed",
    zIndex: "999999"
  },
  containerLink: {
    paddingRight: "1em",
    paddingTop: "0.5em",
    float: "right"
  },
  containerListItem: {
    padding: "1em"
  },
  containerSidePanel: {
    minWidth: "15em"
  },
  link: {
    margin: "0 1em 0.2em 1em",
    display: "inline",
    cursor: "pointer",
    "&:hover": {
      textShadow: "0px 0px 1px #000000"
    }
  },
  linkActive: {
    margin: "0 1em 0.2em 1em",
    cursor: "pointer",
    display: "inline",
    textShadow: "0px 0px 1px #000000"
  }
});

const menuItems = [
  {
    name: "Home",
    icon: <HomeIcon/>
  },
  {
    name: "About",
    icon: <SettingsInputAntennaIcon/>
  },
  {
    name: "Solutions",
    icon: <CloudDownloadIcon/>
  },
  {
    name: "Contact",
    icon: <CallIcon/>
  }
];

export default function Navigation() {
  const classes = useStyles();
  const theme = useTheme();
  const mediaSM = useMediaQuery(theme.breakpoints.down("sm"));
  
  //States
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  
  //Handlers
  const handleClick = location => {
    document.querySelector("#" + location).scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
    
    setActiveLink(location);
  };
  
  return (
    <React.Fragment>
      <Grid
        container
        justify={"flex-start"}
        alignItems={"flex-start"}
      >
        {/*NAVIGATION*/}
        <Grid item xs={12}>
          <Grid
            container
            alignItems={"center"}
            justify={"center"}
            className={classes.container}
          >
            {/*LOGO*/}
            <Grid item xs={2}>
              <TypographyHelper
                variant={"h3"}
                fontFamily={"Public Sans"}
                fontWeight={"700"}
              >
                <Logo/>
              </TypographyHelper>
            </Grid>
            
            {/*LINKS*/}
            <Grid item xs={10}>
              <div className={classes.containerLink}>
                {!mediaSM &&
                menuItems.map((menuItem, index) =>
                  <div key={index} className={activeLink === menuItem.name ? classes.linkActive : classes.link} onClick={() => handleClick(menuItem.name)}>
                    <TypographyHelper
                      variant={"body"}
                      color={"black"}
                      display={"inline"}
                    >
                      {menuItem.name}
                    </TypographyHelper>
                  </div>
                )}
                
                {mediaSM &&
                <IconButton className={classes.link}>
                  <MenuIcon onClick={() => setSideNavOpen(true)}/>
                </IconButton>
                }
                
                {/*DRAWER*/}
                <Drawer anchor={"right"} open={sideNavOpen} onClose={() => setSideNavOpen(false)} style={{zIndex: "9999999"}}>
                  <List className={classes.containerSidePanel}>
                    {menuItems.map((menuItem, index) => (
                      <ListItem button key={index} className={classes.containerListItem} onClick={() => handleClick(menuItem.name)}>
                        <ListItemIcon>{menuItem.icon}</ListItemIcon>
                        <TypographyHelper
                          variant={"body"}
                          color={"black"}
                        >
                          {menuItem.name}
                        </TypographyHelper>
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
