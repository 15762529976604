import React from "react";

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  outerContainer: {
    display: "inline-flex",
    textAlign: "center",
    border: "3px solid rgba(0, 0, 60, 1)",
    borderRadius: "0.1em",
    fontWeight: "800",
    fontSize: "1.1em",
    marginLeft: "0.3em",
    fontFamily: "Public Sans"
  },
  innerContainerLeft: {
    backgroundColor: "transparent",
  },
  innerContainerRight: {
    backgroundColor: "rgba(0, 0, 60, 1)",
    color: "white",
    padding: "0 0.1em"
  },
  wrapper: {
    display: "inline-flex",
  },
  letterWrapperLeft: {
    paddingLeft: "0.1em",
    color: "rgba(0, 0, 60, 1)",
  },
  letterWrapperRight: {
    color: "rgba(0, 0, 60, 1)",
    marginLeft: "-0.3em",
    marginRight: "0.1em",
    zIndex: "-1",
  },
  textWrapper: {
    color: "white",
  }
});

export default function Logo() {
  const classes = useStyles();
  
  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainerLeft}>
        <div className={classes.wrapper}>
          <div className={classes.letterWrapperLeft}>
            B
          </div>
          <div className={classes.letterWrapperRight}>
            C
          </div>
        </div>
      </div>
      <div className={classes.innerContainerRight}>
        <span className={classes.textWrapper} key={"penis"}>Fibre</span>
      </div>
    </div>
  );
}