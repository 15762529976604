import React from "react";
import emailjs from "emailjs-com";

const MailService = {
  send: function (props) {
    try {
      emailjs.send("BCFibre", "default", props.message, "user_12UR7dNJIFcI0SQVO32z5")
             .then((result) => {
               return true
             }, (error) => {
               return false
             });
    } catch (err) {
      console.log(err)
    }
  }
};

export default MailService;