export const light = {
  red: "#fe654f",
  orange: "#ffbc70",
  green: "#c2eabd",
  blue: "#558c8c",
  purple: "#b0a3d4",
  grey: "#f2f2f2",
  black: "#000000",
  white: "#ffffff",
};

export const base = {
  red: "#c1292e",
  orange: "#f28f3b",
  green: "#00a676",
  blue: "#19647e",
  purple: "#5b5f97",
  grey: "#7f7f7f",
  black: "#000000",
  white: "#ffffff",
};

export const dark = {
  red: "#780116",
  orange: "#fc440f",
  green: "#214e34",
  blue: "#0a1b33",
  purple: "#3d325d",
  grey: "#282828",
  black: "#000000",
  white: "#ffffff",
};