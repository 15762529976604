import React from "react";

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "25em",
    height: "32em",
    "&:hover, &:focus": {
      transform: "scale(1.01)",
      cursor: "pointer"
    }
  },
  cardHeader: {
    minHeight: "5em",
    maxHeight: "5em",
    padding: "0 1em",
    textAlign: "center"
  },
  media: {
    minHeight: "10em",
    maxHeight: "10em",
    paddingTop: "56.25%"
  },
  cardContent: {
    padding: "1em 1em",
    minHeight: "15em",
    maxHeight: "15em"
  },
  cardText: {
    textAlign: "justify",
    fontSize: "1em"
  }
});

export default function InfoCard(props) {
  const classes = useStyles();
  
  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title={props.title}
      />
      <CardMedia
        className={classes.media}
        image={props.imageUrl}
        title={props.title}
      />
      <CardContent className={classes.cardContent}>
        <Typography color="textSecondary" className={classes.cardText}>
          {props.content}
        </Typography>
      </CardContent>
    </Card>
  );
}